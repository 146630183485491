exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-page-tsx": () => import("./../../../src/pages/homePage.tsx" /* webpackChunkName: "component---src-pages-home-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-medlemsstatistik-index-tsx": () => import("./../../../src/pages/medlemsstatistik/index.tsx" /* webpackChunkName: "component---src-pages-medlemsstatistik-index-tsx" */),
  "component---src-pages-medlemsstatistik-lejemaal-tsx": () => import("./../../../src/pages/medlemsstatistik/lejemaal.tsx" /* webpackChunkName: "component---src-pages-medlemsstatistik-lejemaal-tsx" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-pages-test-to-index-js": () => import("./../../../src/pages/test_to/index.js" /* webpackChunkName: "component---src-pages-test-to-index-js" */),
  "component---src-pages-test-to-ronnie-index-js": () => import("./../../../src/pages/test_to/ronnie/index.js" /* webpackChunkName: "component---src-pages-test-to-ronnie-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-yoyo-tsx": () => import("./../../../src/pages/yoyo.tsx" /* webpackChunkName: "component---src-pages-yoyo-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

